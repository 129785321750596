import { defineStore } from 'pinia'
import type { DashboardParams } from '~/types/query-params'

export const useFilterStore = defineStore('filter', () => {
  // state
  const params: DashboardParams = useUrlSearchParams('history', { removeFalsyValues: true, removeNullishValues: true })

  // actions
  function $reset() {
    params.relativeDate = 'previous|7|day'

    for (const key in params) {
      if (key !== 'relativeDate' && key !== 'hash') {
        params[key as keyof typeof params] = undefined
      }
    }
  }

  return {
    params,
    $reset
  }
})
